// The "Modals" library
(function () {
  const closeModal = (modal) => {
    modal.addEventListener(
      'transitionend',
      () => {
        modal.remove();
      },
      false,
    );

    modal.classList.add('ease-in', 'transform', 'duration-200');
    modal.classList.add('opacity-0', 'scale-110');
  };

  document.querySelectorAll('.modal').forEach((modal) => {
    const close = (e) => {
      e.preventDefault();
      e.stopPropagation();
      closeModal(modal);
    };

    modal.querySelectorAll('.modal-close-button').forEach((closeBtn) => {
      closeBtn.addEventListener('click', close, false);
    });
  });

  window.addEventListener(
    'keypress',
    (e) => {
      if (e.key !== 'Escape') return;
      const activeModals = document.querySelectorAll('.modal');
      if (activeModals.length === 0) return;
      activeModals.forEach(closeModal);
    },
    false,
  );
})();

// The "Checkbox" library
(function () {
  // <span>
  //   <button type="button" class="toggle" role="switch" aria-checked="false">
  //     <span aria-hidden="true" class="toggle__thumb"></span>
  //   </button>
  //   <input class="hidden" name="name" type="checkbox">
  // </span>
  document.querySelectorAll('button.toggle').forEach((button) => {
    button.addEventListener('click', ({ target }) => {
      const checked = target.getAttribute('aria-checked') === 'true';
      target.setAttribute('aria-checked', String(!checked));
      target.nextElementSibling.value = String(!checked);
    });
  });
})();

// The "Alerts" library
(function () {
  // Example:
  // <div id="foobar" class="alert" data-closes="#foobar" data-request="/foo/bar.php">
  //   Hello I am dismissable!
  //   <button class="close-alert">Close</button>
  // </div>

  // When the element with tag "close-alert" is clicked, it will remove the "data-closes"
  // element (querySelector) from the current DOM, and if data-request is defined it will
  // send a GET request there.

  document.querySelectorAll('.alert[data-closes]').forEach((alert) => {
    let target = document.querySelector(alert.dataset.closes);
    let closeButton = alert.querySelector('.close-alert');
    if (!target || !closeButton) return;

    closeButton.addEventListener(
      'click',
      (e) => {
        target.remove();

        if (alert.dataset.request) {
          fetch(alert.dataset.request);
        }
      },
      false,
    );
  });
})();

// The "FAQ" library
(function () {
  // Example DOM:
  // <dl class="faq [...]">
  //   <div class="pt-6">
  //     <dt class="text-lg">
  //       <button type="button">
  //         ...
  //       </button>
  //     </dt>
  //     <dd class="hidden">
  //       answer
  //     </dd>
  //   </div>
  // </dl>

  document.querySelectorAll('.faq').forEach((container) => {
    Array.prototype.slice
      .call(container.childNodes)
      .filter((el) => el.nodeName == 'DIV')
      .forEach((el) => {
        let button = el.querySelector('dt button');
        let svg = button.querySelector('svg');
        let answer = el.querySelector('dd');

        button.addEventListener(
          'click',
          (e) => {
            if (answer.classList.contains('hidden')) {
              answer.classList.remove('hidden');
              svg.classList.remove('-rotate-180');
              svg.classList.add('rotate-0');
            } else {
              answer.classList.add('hidden');
              svg.classList.add('-rotate-180');
              svg.classList.remove('rotate-0');
            }
          },
          false,
        );
      });
  });
})();

var selectNavigation = document.getElementsByClassName('select-navigation');

for (var i = 0; i < selectNavigation.length; i++) {
  selectNavigation[i].onchange = function () {
    var selectedOption = this.value;
    window.location.href = selectedOption;
  };
}
